<template>
  <div class="new_ProductsBox">
    <div
      class="goods-item"
      v-for="(item, index) in list"
      :key="index"
      @click="Detail(item)"
    >
      <div>
        <img :src="item.image" alt="" />
      </div>
      <p>{{ item.store_name }}</p>
      <div>
        <span>优惠价</span>
        <span>￥{{ item.price }}</span>
        <span style="margin-left: 15px">￥{{ item.ot_price }}</span>
      </div>
      <!-- <span>已抢 {{ item.rush }}件</span> -->
    </div>
  </div>
</template>
<script>
export default {
  name: "New_ProductsBox",
  props: {},

  data() {
    return {
      list: [],
    };
  },
  methods: {
    Detail(e) {
      
      this.$router.push({
        path: "/details",
        query: {
          data: e.id,
        },
      });
    },
    //获取精品推荐
    getgroom() {
      let data = {
        limit: 0,
        page: 0,
      };
      this.$api.Groomlist(data).then((res) => {
        this.list = res.data.list.splice(0, 5);
        // console.log("精品推荐",this.list);
      });
    },
  },
  created() {
  
  },
};
</script>
<style lang="scss" scoped>
.new_ProductsBox {
  width: 100%;
  margin: 0 auto;
  display: flex;
  overflow-x: scroll;
  padding: 10px 0;

  &::-webkit-scrollbar {
    display: none;
  }
  & > :first-child:hover {
    box-shadow: 3px 0px 8px 2px rgba($color: #999, $alpha: 0.2);
  }
  & > :last-child:hover {
    box-shadow: -3px 0px 8px 2px rgba($color: #999, $alpha: 0.2);
  }
  & > :not(:first-child, :last-child):hover {
    box-shadow: 0px 0px 8px 2px rgba($color: #999, $alpha: 0.2);
  }
  & > :not(:first-child) {
    margin-left: 20px;
  }
}
.goods-item {
  width: 224px;
  height: 320px;
  padding: 16px 16px 22px;
  background-color: #fff;
  box-sizing: border-box;
  cursor: pointer;
  & > :first-child {
    width: 192px;
    margin: 0 auto;
    height: 192px;
    & > img {
      width: 100%;
      height: 100%;
    }
  }
  & > p {
    margin: 12px 0 0 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #282828;
    font-size: 14px;
  }
  & > :nth-child(3) {
    margin-top: 10px;
    & > :not(:first-child) {
      margin-left: 10px;
    }
    & > span {
      display: inline-block;
      text-align: center;
    }
    & > :first-child {
      color: #fff;
      background-color: red;
      font-size: 12px;
      width: 45px;
      height: 20px;
      line-height: 20px;
    }
    & > :nth-child(2) {
      font-size: 18px;
      font-weight: 700;
      color: #e93323;
    }
    & > :last-child {
      text-decoration: line-through;
      font-size: 12px;
      color: #a3a3a3;
    }
  }
  & > span {
    display: block;
    margin-top: 14px;
    font-size: 12px;
    color: #969696;
  }
}
</style>