<template>
  <div class="goodsList" @click="detail(item)">
    <div>
      <img :src="item.image" alt="" />
    </div>
    <div>
      <span>￥{{ item.price }}</span>
      <span>￥{{ item.ot_price }}</span>
    </div>
    <div>
      {{ item.store_name }}
    </div>
    <div>已售{{ item.sales + item.ficti }}件</div>
  </div>
</template>
<script>
export default {
  name: "GoodsList",
  data() {
    return {};
  },
  props: {
    item: {
      type: Object,
    },
  },
  methods: {
    box() {
      //  console.log("515",this.$refs.boox);
    },
    detail(item) {
      this.$router.push({
        path: "/details",
        query: {
          data: item.id,
        },
      });
    },
  },
  mounted() {},
};
</script>
<style scoped lang="scss">
.goodsList {
  width: 18.66%;
  height: 340px;
  padding: 16px;
  background-color: #fff;
  cursor: pointer;

  & > div {
    width: 192px;
    margin: 0 auto;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  & > :first-child {
    height: 192px;
    & > img {
      width: 100%;
      height: 100%;
    }
  }
  & > :nth-child(2) {
    margin-top: 12px;
    & > :first-child {
      font-size: 18px;
      font-weight: bold;
      color: #e93323;
    }
    & > :last-child {
      text-decoration: line-through;
      display: inline-block;
      font-size: 12px;
      color: #aaa;
      margin-left: 8px;
    }
  }
  & > :nth-child(3) {
    margin-top: 8px;
    color: #5a5a5a;
    -webkit-line-clamp: 1;
    font-size: 14px;
    height: 37px;
  }
  & > :last-child {
    margin-top: 10px;
    font-size: 12px;
    color: #aaa;
  }
}
.goodsList:hover {
  box-shadow: 0px 0px 8px 2px rgba($color: #999, $alpha: 0.2);
}
</style>