<template>
  <div>
    <!-- 导航栏 -->
    <div class="home_nav" style="background: #3c3d41">
      <div class="home_nav_content">
        <div class="home_nav_content_left">
          <div
            class="logoimg"
            v-if="object.configData"
            :style="'background-image: url(' + object.configData.logo + ')'"
          >
            <!-- <img
              :src="object.configData.logo"
              style=" object-fit: cover;
            width:140px;height:60px"
            /> -->
          </div>
          <div
            style="margin-left: 40px; margin: 0 auto"
            v-for="(item, index) in navtext"
            :key="index"
          >
            <div
              @click="mouseOver(item, index)"
              :class="{ red: active === item, Navtext: active !== item }"
            >
              {{ item }}
            </div>
          </div>
        </div>
        <!-- 输入框 -->
        <div class="nav_rigth">
          <el-input placeholder="搜索商品" v-model="inputvalue">
            <el-button slot="append" @click="Input" style="padding: 0 30px"
              >搜索</el-button
            >
          </el-input>
        </div>
      </div>
    </div>

    <!--  -->
    <router-view></router-view>

    <!-- 公共路由底部 -->
    <div class="bottom">
      <div class="content_box">
        <div>
          <div style="display: flex; justify-content: space-around">
            <div style="margin-top: 30px">
              <div
                class="logoimg"
                v-if="object.configData"
                :style="'background-image: url(' + object.configData.logo + ')'"
              ></div>
            </div>
            <div>
              <div>品牌保障</div>
              <div style="font-size: 10px">Brand guarantee</div>

              <div style="margin-top: 15px; font-size: 10px">
                <div style="margin-bottom: 15px">
                  <img src="../../assets/icon(1).png" alt="" />
                  <span style="margin-left: 15px; opacity: 1"
                    >品类众多 全屋配齐</span
                  >
                </div>
                <div style="margin-bottom: 15px">
                  <img
                    src="../../assets/s.png"
                    alt=""
                    style="width: 15px; height: 15px"
                  />

                  <span style="margin-left: 12px">精选爆款 天天钜惠</span>
                </div>
                <div style="margin-bottom: 15px">
                  <img src="../../assets/x.png" alt="" />
                  <span style="margin-left: 12px"> 全国门店 尊享服务</span>
                </div>
                <div style="margin-bottom: 15px">
                  <img src="../../assets/icon(3).png" alt="" />
                  <span style="margin-left: 12px"> 质量保障 售后无忧</span>
                </div>
              </div>
            </div>
            <div>
              <div>友情链接</div>
              <div style="font-size: 10px">Links</div>
              <div
                style="margin-top: 15px; font-size: 10px"
                v-for="(item, index) in object.footerList"
                :key="index"
              >
                <div
                  style="margin-bottom: 15px; cursor: pointer"
                  @click="title(item)"
                >
                  {{ item.title }}
                </div>
              </div>
            </div>
            <div>
              <div>联系我们</div>
              <div style="font-size: 10px">Brand guarantee</div>

              <div style="margin-top: 15px; font-size: 10px">
                <div style="margin-bottom: 15px" v-if="object.configData">
                  <img
                    src="../../assets/dh.png"
                    alt=""
                    style="width: 16px; height: 16px"
                  />
                  <span style="margin-left: 15px">
                    {{ object.configData.tel }}
                  </span>
                </div>
                <div style="margin-bottom: 15px">
                  <img
                    src="../../assets/dw.png"
                    alt=""
                    style="width: 16px; height: 18px"
                  />
                  <span style="margin-left: 15px" v-if="object.configData">
                    {{ object.configData.address }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="bottom_content_bottom">
          <div
            style="
              width: 1200px;
              color: #ffffff;
              text-align: center;
              padding: 20px 0;
              border-top: 1px solid #fff;
              margin-top: 20px;
              opacity: 0.2;
            "
            v-if="object.configData"
          >
            {{ object.configData.copyright }} |
            {{ object.configData.copyright_record_number }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Layout",
  props: {},
  data() {
    return {
      logo: "", //logo
      navtext: ["首页", "产品分类", "限时秒杀"], //导航栏
      Nav: [
        { name: "首页", path: "/" },
        { name: "产品分类", path: "/product_details" },
        { name: "限时秒杀", path: "/Hunting_moment" },
      ], //导航栏
      inputvalue: "", //搜索框
      active: "", //导航栏
      object: [],
      isshow: false,
      loading: false,
      time: null,
    };
  },
  components: {},
  methods: {
    title(e) {
      window.open(e.url, "_blank");
    },
    //  导航栏搜索框
    Input() {
      this.active = "产品分类";
      this.$router.push({
        path: "/product_details",
        query: {
          data: this.inputvalue,
        },
      });
    },
    //导航栏鼠标点击事件
    mouseOver(item, index) {
      this.inputvalue = "";
      this.active = item;
      let a = this.$router.options.routes[0].children.filter((i) => {
        return i.meta.title === item;
      });
      this.$router.push(a[0].path);
    },

    getgetConfig() {
      this.loading = true;
      this.$api.getConfig().then((res) => {
        this.object = res.data;
        this.loading = false;
      });
    },
  },
  mounted() {
    this.active = this.$route.meta.title;
  },
  computed: {},
  watch: {
    $route(to, from) {
      console.log(to);
      if (to.name == "details") {
        this.active = "";
      }
      if (to.name == "product_details") {
        this.active = "产品分类";
      }
       if (to.name == "home") {
        this.active = "首页";
      }
    },
  },
  created() {},
  beforeDestroy() {},
};
</script>
<style lang='scss' scoped>
* {
  margin: 0;
  padding: 0;
}
.logoimg {
  margin: 0 10px;
  width: 150px;

  height: 100px;
  margin-right: 40px;
  background-size: 65% auto;
  background-position: center;
  background-repeat: no-repeat;
}
.content_box {
  width: 1200px;
  height: 180px;

  font-size: 14px;
  color: #ffffff;

  margin: 0 auto;
}
.bottom {
  width: 100%;
  height: 335px;
  background: #3c3d41;
  opacity: 1;
  border-radius: 0px;
  opacity: 1;

  display: flex;
  align-items: center;
  position: relative;

  &_content {
    width: 1200px;
    margin: 0 auto;
  }
}

.home_nav_content {
  margin: 0 auto;
  width: 1200px;
  height: 100%;
  color: #fff;

  line-height: 40px;
  display: flex;
  justify-content: space-between;

  &_left {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 750px;

    margin-right: 20px;
  }
}
.nav_rigth {
  width: 350px;
  height: 100%;
  margin-top: 25px;
}

.Navtext:hover {
  border-radius: 10px;

  color: #fff;
}
.laft_classify {
  width: 15%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 0px;
  z-index: 99;
}
.classify_content {
  width: 50%;
  height: 100%;
  background: #ffff;
  position: absolute;
  left: 15%;
  top: 0;
  z-index: 99;
  box-shadow: 2px 2px 10px gray;
}
.list {
  width: 100%;
  height: 50px;
  line-height: 50px;
  cursor: pointer;
  color: #fff;
  display: flex;
  justify-content: space-around;
  &_item {
    width: 100px;
    text-align: center;
  }
  &_img {
    text-align: center;
  }
}
.List {
  width: 100%;
  height: 50px;
  background: red;
  line-height: 50px;
  cursor: pointer;
  color: #fff;
  display: flex;
  justify-content: space-around;
  &_item {
    width: 100px;
    text-align: center;
  }
  &_img {
    text-align: center;
  }
}
.home_nav {
  width: 100% !important;

  padding: 8px 0;
}
// 轮播图
.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}
.Navtext {
  margin: 0 20px;
  cursor: pointer;
}
.red {
  color: rgb(255, 60, 0);
  margin: 0 20px;
  cursor: pointer;
  border-bottom: 2px solid rgb(255, 60, 0);
}
</style>6