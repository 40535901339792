<template>
  <div class="card">
    <div>
      <div>{{ title }}</div>
    </div>
  
  <a  @click="detail" style="color:  #5f5f5f;">更多 ></a>
   
  
  </div>
</template>
<script>
export default {
  name: "",
  props: {
    title: {
      type: String,
    },
    text: {
      type: String,
    },
  },
  data() {
    return {};
  },
  components: {},
  methods: { 
    detail() {
      this.$router.push("/product_details")
  } },
  mounted() {},
  computed: {},
  watch: {},
};
</script>
<style lang='scss' scoped>
.card {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 30px auto 15px auto;
  align-items: center;
  & > div {
    display: flex;
    & > :first-child {
      width: 110px;
      height: 30px;
      margin-right: 13px;
    }
    & > :last-child {
      font-size: 22px;
        font-weight: 700;
        color: #333;
      line-height: 30px;
    }
  }
  & > a {
    text-decoration: none;
    display: inline-block;
    color: #818181;
    width: 58px;
    height: 24px;
    font-size: 12px;
    cursor: pointer;
    border: 1px solid #5f5f5f;
    
    text-align: center;
    line-height: 24px;
  }
}
</style>