<template>
  <div class="Goods_Box" v-if="seckill_list.length != 0">
    <div class="seckill">
      <h2>限时秒杀</h2>
      <p>
        <span style="margin-top:10px">{{title}}点场</span> 
        <span
          v-if="
            dayString == 0 &&
            hourString == 0 &&
            minuteString == 0 &&
            secondString == 0
          "
          style="margin-top: 20px"
          >暂无秒杀</span
        >
        <span v-else style="margin-top: 20px">距离结束还剩</span>
        <span>——</span>
      </p>
      <div>
        <!-- <span>{{ dayString }}</span
        >天 -->
        <span style="background-color: #000; padding: 2px">{{
          hourString
        }}</span
        >小时 <span  style="background-color: #000; padding: 2px">{{ minuteString }}</span
        >分钟
        <span style="background-color: #000; padding: 2px">{{
          secondString
        }}</span
        >秒
      </div>
    </div>
    <div class="seckillBox">
      
      <Seckill :seckill_list="seckill_list"></Seckill>
    </div>
  </div>
</template>
<script>
import Seckill from "./seckillGoods.vue";
import Date from "@/api/new_Date";
export default {
  name: "GoodsBox",
  props: {
    remainTime: {
      // 倒计时间总秒数
      type: [Number,null],
      default: 5,
    },
    // 秒杀时间段数组
    seckill_list: {
      type: Array,
     
    },
    // 秒杀时间
    title:{
      type:String
    }
  },

  data() {
    return {
      day: "",
      hour: "",
      minute: "",
      second: "",
     list:[],
      promiseTimer: "",
      time:null
    
    };
  },
  computed: {
    hourString() {
      return this.formatNum(this.hour);
    },
    minuteString() {
      return this.formatNum(this.minute);
    },
    secondString() {
      return this.formatNum(this.second);
    },
    dayString() {
      return this.formatNum(this.day);
    },
  },
  mounted() {
   
    setTimeout(() => {
      if (this.remainTime > 0) {
        this.day = Math.floor(this.remainTime / 3600 / 24);
        this.hour = Math.floor((this.remainTime / 3600) % 24);
        this.minute = Math.floor((this.remainTime / 60) % 60);
        this.second = Math.floor(this.remainTime % 60);
        this.countDowm();
      }
   
    }, 500);
  },
  methods: {
    countDowm() {
      var self = this;
     
      this.promiseTimer = setInterval(function () {
       
        if (self.day === 0) {
          if (self.hour === 0) {
            if (self.minute !== 0 && self.second === 0) {
              self.second = 59;
              self.minute -= 1;
            } else if (self.minute === 0 && self.second === 0) {
              self.second = 0;
              self.$emit("countDowmEnd", true);
              clearInterval(self.promiseTimer);
            } else {
              self.second -= 1;
            }
          } else {
            if (self.minute !== 0 && self.second === 0) {
              self.second = 59;
              self.minute -= 1;
            } else if (self.minute === 0 && self.second === 0) {
              self.hour -= 1;
              self.minute = 59;
              self.second = 59;
            } else {
              self.second -= 1;
            }
          }
        } else {
          if (self.hour === 0 && self.minute === 0 && self.second === 0) {
            self.day -= 1;
            self.hour = 23;
            self.minute = 60;
            self.second = 60;
          } else {
            if (self.hour === 0) {
              if (self.minute !== 0 && self.second === 0) {
                self.second = 59;
                self.minute -= 1;
              } else if (self.minute === 0 && self.second === 0) {
                self.second = 0;
              } else {
                self.second -= 1;
              }
            } else {
              if (self.minute !== 0 && self.second === 0) {
                self.second = 59;
                self.minute -= 1;
              } else if (self.minute === 0 && self.second === 0) {
                self.hour -= 1;
                self.minute = 59;
                self.second = 59;
              } else {
                self.second -= 1;
              }
            }
          }
        }

       
      }, 1000);

    },
    formatNum(num) {
      return num < 10 ? "0" + num : "" + num;
    },
  },
  components: {
    Seckill,
  },

  beforeDestroy(){
     clearInterval(this.time);
     this.time = null
  },
  created() { 
    clearInterval(this.promiseTimer);
       this.time =setInterval(()=>{
          if (
          this.dayString == "00" &&
          this.hourString == "00" &&
          this.minuteString == "00" &&
          this.secondString == "00"
        ) {
         
         this.$parent.del();
         
       
        }
        
      },1000)},
};

</script>
<style scoped lang="scss">
.Goods_Box {
  width: 100%;
  margin: 30px auto 0 auto;
  display: flex;
  .seckill {
    width: 18%;
    margin-top: 10px;
    background-color: red;
    border-radius: 10px;
    height: 260px;
    text-align: center;
    color: #fff;
    font-size: 14px;
    & > h2 {
      display: block;
      margin-top: 40px;
    }
    & > p {
      width: 100%;
      & > span {
        display: block;
      }
      & > :nth-child(2) {
        font-weight: bold;
        margin: 5px 0;
      }
    }
    & > div {
      margin-top: 30px;
      & > span {
        display: inline-block;
      }
      & > :nth-child(odd) {
        background-color: #000;
        padding: 2px;
      }
      & > :nth-child(even) {
        margin: 0 7px;
      }
    }
  }
  .seckillBox {
    width: 81%;
    height: 274px;
  }
}
</style>