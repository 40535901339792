import { axos } from "./http"



export default {
    // 获取id
    shopid() {
        return axos(`shopPc/getShopId?shop_name=elian`)        //  shop_name  =  kzzj  || elian
    },
    // 首页获取轮播图片
    getbanner() {
        return axos("shopPc/banner")
    },
    // 友情链接获取
    getConfig() {
        return axos("shopPc/getConfig")
    },
    // 获取首页首发新品
    groomlist(data) {
        return axos(`groom/list/3?limit=${data.limit}&page=${data.page}`)
    },
    // 获取首页精品推荐
    Groomlist(data) {
        return axos(`groom/list/1?limit=${data.limit}&page=${data.page}`)
    },
    // 获取分类
    shopPc() {
        return axos("shopPc/cate")
    },
    // 获取商品列表
    getlist(params) {
        return axos(`/front/foreign/getProductLst?keyword=${params.keyword}&sid=${params.sid}&cid=${params.cid}&page=${params.page ?? 1}&limit=${params.limit}`)

    },
    // 获取秒杀时间段
    seckill() {
        return axos("seckill/index")
    },
    // 查询商品
    detail(id) {
        return axos("product/detail/" + id)
    },
    // 获取商品二维码
    goodscode(data) {
        return axos(`shopPc/goodsCode?id=${data.id}&type=${data.type}`)
    },
    // 查询秒杀商品
    skillGoods(id) {
        return axos(`seckill/list/${id}?limit=50`)
    }
















}
