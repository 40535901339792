import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/home/home'
import Layout from '../views/layout/layout'
import View from '../views/home/index'
Vue.use(VueRouter)


const routes = [

  // 公共路由
  {
    path: '',
    name: 'layout',
    component: Layout,
    children: [

      {
        path: '/',
        name: 'home',
        component: HomeView,
        meta: {
          title: "首页"
        }
      },
      {
        path: '/details',
        name: 'details',
        component: () => import('../views/details/details'),
        meta: {
          title: "详情页"
        }
      },
      {
        path: '/product_details',
        name: 'product_details',
        component: () => import('../views/product_details/product_details'),
        meta: {
          title: "产品分类"
        }
      },
      {
        path: '/Hunting_moment',
        name: 'Hunting_moment',
        component: () => import('../views/Hunting_moment/Hunting_moment'),
        meta: {
          title: "限时秒杀"
        }
      }

    ],


  },
  {
    path: '*',
    name: 'Hunting_moment',
    component: () => import('../views/home/err'),
    meta: {
      title: "err"
    }
  }

]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, form, next) => {

  // document.title = to.meta.title //浏览器标题
  document.documentElement.scrollTop = 0


  next()


  // if (to.path === '/') {
  //   let url = window.location.href;
  //   let shopname = url.split("?")[1];
  //   if (shopname !== undefined) {
  //     localStorage.setItem("key", shopname)
  //   }
  //   next()
  // }
  // let key = localStorage.getItem("key")
  // if (key) {
  //   next()
  // } else {
  //   next('*')
  // }

})
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}


export default router
