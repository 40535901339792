<template>
  <div style="margin-left: 10px">
    <div v-if="seckill_list">
      <div
        style="
          width: 98%;
          height: 280px;
          
          margin-left: 20px;
          position: relative;
         
        "
      >
        <swiper :options="swiperOption" ref="mySwiper">
          <swiper-slide v-for="(item, index) in seckill_list" :key="index">
            <div class="swiper" @click="detai(item)">
              <div>
                <div style="width: 164px; height: 100%">
                  <img :src="item.image" style="width: 168px; height: 168px;margin-top:-12px" />
                </div>

                <div
                  style="
                    width: 164px;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    margin-top: 15px;
                  "
                >
                  {{ item.title }}
                </div>
                <div style="margin-top:10px">
                  <span style="color: red; font-size: 18px; font-weight: bolder"
                    >￥{{ item.price }}</span
                  >
                  <del style="font-size: 12px; margin-left: 5px;"
                    >￥{{ item.ot_price }}</del
                  >
                </div>
              </div>
            </div>
          </swiper-slide>

          <div class="sss" slot="button-prev" v-if="seckill_list.length > 4">
            <i class="el-icon-arrow-left"></i>
          </div>
          <div class="nnn" slot="button-next" v-if="seckill_list.length > 4">
            <i class="el-icon-arrow-right"></i>
          </div>
        </swiper>
      </div>
    </div>
    <div class="seckill" v-else-if="seckill_list === null">
      <h2>暂无秒杀商品！</h2>
    </div>
  </div>
</template>
<script>
export default {
  name: "seckill",
  props: {
    seckill_list: {
      type: Array,
    },
  },
  data() {
    return {
      show: true,
      swiperOption: {
        //可见图片张数
        slidesPerView: "auto",
        // 默认选中中间一张
        // centeredSlides: true,
        //无限循环
        // loop: true,
        //小圆点（我此处没使用，列出供参考）
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        //自动轮播
        // autoplay: {
        //   stopOnLastSlide: true,
        // },
        //上下按钮点击轮播效果
        navigation: {
          nextEl: ".sss",
          prevEl: ".nnn",
        },
      },
    };
  },
  methods: {
    detai(item) {
      this.$router.push({
        path: "/details",
        query: {
          data: item.id,
          type: 1,
        },
      });
    },
  },
  created() {},
};
</script>
<style lang="scss" scoped>
.swiper-slide {
  transition: 1s;
  transform: scale(0.85);
}

.swiper-slide {
  width: 240px !important;
  
}
.swiper {
  width: 230px;
  height: 305px;
  background: rgb(255, 255, 255);
  border-radius: 8px;
  margin-top: -12px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.swiper-button-next,
.swiper-button-prev {
  color: #c4c3c3 !important;
}
.sss {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #c4c3c3;
  border-radius: 20px;
  padding: 3px;
  position: absolute;
  transform: translateY(-50%);
  z-index: 1;
  top: 50%;
  font-size: 22px;
  left: 0px;
}
.nnn {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #c4c3c3;
  border-radius: 20px;
  padding: 3px;
  position: absolute;
  transform: translateY(-50%);
  font-size: 22px;
  z-index: 1;
  top: 50%;
  right: 10px;
}

.seckill {
  width: 80%;
  margin: 0 auto;
  margin-top: 120px;
  text-align: center;
  color: #c4c3c3;
}
#goodsList {
  width: 248px;
  text-align: center;
  padding: 25px 0;
  height: 100%;
  margin: 0 100px;
  & > div {
    width: 164px;
    margin: 0 auto;
    & > div {
      width: 100%;
      margin: 0 auto;
    }
    & > :first-child {
      height: 164px;
      & > img {
        width: 100%;
        height: 100%;
      }
    }
    & > :nth-child(2) {
      color: #282828;
      font-size: 14px;
      font-weight: 600;
      margin: 10px auto;
      overflow: hidden;
      text-overflow: ellipsis;
      -o-text-overflow: ellipsis;
      white-space: nowrap;
    }
    & > :last-child {
      display: flex;
      justify-content: center;
      overflow: hidden;
      text-overflow: ellipsis;
      -o-text-overflow: ellipsis;
      white-space: nowrap;
      & > span {
        display: inline-block;
        text-align: center;
      }
      & > :first-child {
        color: red;
        font-size: 16px;
        font-weight: 700;
      }
      & > :last-child {
        color: #a3a3a3;
        text-decoration: line-through;
        font-size: 14px;
        margin-left: 6px;
      }
    }
  }
}
</style>