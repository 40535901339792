<template>
  <div></div>
</template>
<script>
export default {
  name: "",
  props: {},
  data() {
    return {};
  },
  components: {},
  methods: {},
  mounted() {},
  computed: {},
  watch: {},
};
</script>
<style lang='scss' scoped>
</style>