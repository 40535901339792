<template>
  <div style="background: rgb(230, 230, 230); padding: 20px 0">
    <!-- 轮播图 -->
    <div
      style="width: 1200px; margin: 0 auto; position: relative; cursor: pointer"
    >
      <el-carousel
        :interval="3000"
        height="420px"
        trigger="click"
        arrow="never"
        ref="cardShow"
      >
        <el-carousel-item v-for="(item, index) in bannerData" :key="index">
          <img
            :src="item.link_url"
            alt=""
            height="100%"
            width="100%"
            @click="clickImg(item)"
          />
        </el-carousel-item>
      </el-carousel>
      <div @mouseleave="mouseLeave">
        <div class="laft_classify">
          <template>
            <div class="classifytop" slot="button-prev">
              <i class="el-icon-arrow-up"> </i>
            </div>
          </template>

          <swiper
            :options="swiperOption"
            ref="mySwiper"
            style="height: 360px; margin: 30px 0"
          >
            <swiper-slide v-for="(item, index) in classify" :key="index">
              <div
                :class="{ List: Active === index }"
                class="list"
                @click="listtext(item, index)"
              >
                <div class="list_item">{{ item.cate_name }}</div>
                <div class="list_img">
                  <i class="el-icon-arrow-right"></i>
                </div>
              </div>
            </swiper-slide>
          </swiper>
          <template>
            <div class="classifybottom" slot="button-prev">
              <i class="el-icon-arrow-down"> </i>
            </div>
          </template>
        </div>

        <!--左边分类鼠标移入详情   -->
        <div
          class="classify_content"
          :class="{
            classify_content_a: arr.length < 3 && arr.length > 1,
            classify_content_b: arr.length < 2,
          }"
          v-show="Active !== null"
        >
          <div class="classify_list">
            <div v-for="(i, j) in arr" :key="j">
              <div class="item" @click="Sid(i.id)">
                <div>
                  <img
                    :src="
                      i.pic
                        ? i.pic
                        : 'https://crmeb-fookshop.oss-cn-beijing.aliyuncs.com/attach/2022/04/dcd9e202204091621076064.png'
                    "
                    style="
                      width: 40px;
                      height: 40px;
                      border-radius: 50%;
                      margin-top: 4px;
                    "
                  />
                </div>
                <div style="margin-left: 10px" class="text_name">
                  {{ i.cate_name }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="next_anniu">
      <!-- <div class="anniu" @click="banner_next(0)">
        <i class="el-icon-arrow-left" style="color: #ffff"></i>
      </div> -->

      <!-- <div class="anniu_right" @click="banner_next(1)">
        <i class="el-icon-arrow-right" style="color: #ffff"></i>
      </div> -->
    </div>
    <div class="totop" @click="top" v-if="nav_show">
      <i class="el-icon-arrow-up" font-size="20"></i>
    </div>
    <div style="width: 1200px; margin: 0 auto">
      <div
        v-if="seckill_list"
        style="
          cursor: pointer;

          border-radius: 20px;
        "
      >
        <SeckillBox
          :seckill_list="seckill_list"
          :remainTime="remainTime"
          :title="title"
        ></SeckillBox>
      </div>
      <div>
        <CardBox title="首发新品"></CardBox>
      </div>
      <BoutiqueBox :goods_List="Goods_List" ref="BoutiqueBox"></BoutiqueBox>
      <div style="margin-top: 45px">
        <CardBox title=" 精品推荐"></CardBox>
      </div>
      <NewProductsBox ref="home"></NewProductsBox>
      <Goodsboxs
        v-for="(item, index) in classify"
        :key="index"
        :item="item"
      ></Goodsboxs>
    </div>
  </div>
</template>

<script>
import SeckillBox from "@/components/Home/SeckillBox";
import CardBox from "@/components/Home/CardBox";
import BoutiqueBox from "@/components/Home/BoutiqueBox";
import NewProductsBox from "@/components/Home/New_ProductsBox";
import Goodsboxs from "@/components/Home/GoodsBox";

export default {
  name: "Home",

  props: {},
  data() {
    return {
      inject: ["reload"], //  此处引入在app中定义的reload方法
      loading: false,
      Time: null,
      image: [
        "https://qiniu.crmeb.net/attach/2021/10/33f0e202110151237429958.png",
        "https://qiniu.crmeb.net/attach/2021/10/f8920202110151238001978.png",
      ],
      bannerData: [],
      active: 0, //导航栏
      Active: null, //分类栏
      classify: [],
      arr: [],
      Goods_List: [],
      remainTime: 0,
      seckill_list: [],
      nav_show: false,
      title: "",
      time: null,
      cid: null,
      sid: null,
      swiperOption: {
        autoHeight: true, // 高度自适应
        slidesPerView: 7,

        direction: "vertical", // 竖向滚动
        //可见图片张数

        // 默认选中中间一张
        // centeredSlides: true,
        //无限循环
        // loop: true,

        //上下按钮点击轮播效果
        navigation: {
          nextEl: ".classifybottom",
          prevEl: ".classifytop",
        },
      },
    };
  },
  components: {
    SeckillBox,
    CardBox,
    BoutiqueBox,
    NewProductsBox,
    Goodsboxs,
  },
  beforeCreate() {
    
  },

  methods: {
    top() {
      document.documentElement.scrollTop = 0;
    },
    clickImg(e) {
      if (e.type == 1) {
        window.open(e.external_links, "_blank");
      } else {
        this.$router.push({
          path: "/details",
          query: {
            data: e.goods_id,
          },
        });
      }
    },
    // 分类鼠标移入事件
    listtext(item, index) {
      this.cid = item.id;

      this.arr = item.child;

      this.Active = index;
    },
    // 分类鼠标移出事件
    mouseLeave() {
      this.Active = null;
    },
    banner_next(e) {
      if (e == 0) {
        this.$refs.cardShow.prev();
      } else {
        this.$refs.cardShow.next();
      }
    },

    Sid(e) {
      // console.log(this.cid, e);
      this.sid = e;
      this.$router.push({
        path: "/product_details",
        query: {
          cid: this.cid,
          sid: this.sid,
        },
      });
    },

    getseck() {
      this.$api.seckill().then((res) => {
        res.data.seckillTime.map((item) => {
          if (item.status == 1) {
            this.gettimes(item.stop);
            this.getSkillGoods(item.id);
          } else {
            return;
          }
        });
      });
    },
    getarr() {
      this.$parent.getgetConfig();
      this.$refs.BoutiqueBox.arr();
      this.$refs.home.getgroom();
      this.$api.getbanner().then((res) => {
        this.bannerData = res.data.sort(function (a, b) {
          return a.sort - b.sort;
        });

        this.$api.shopPc().then((res) => {
          this.classify = res.data;
          this.getseck();
          this.classify.map((item) => {
            item.child.map((j) => {
              j.show = true;
            });
          });
        });
      });
    },

    getbanner() {
      this.$api.shopid().then((res) => {
        localStorage.setItem("id", res.data.shop_id);
        if (localStorage.getItem("id")) {
          this.getarr();
        }
      });
    },
    gettimes(data) {
      var times = Math.round(new Date() / 1000); //当前的时间搓

      this.remainTime = data - times; //返回 - 当前
    },
    getSkillGoods(id) {
      this.$api.skillGoods(id).then((res) => {
        this.seckill_list = res.data;
      });
    },
    handleScroll() {
      // 页面滑动的距离
      let scrollTop = document.documentElement.scrollTop;
      // 当页面滑动的距离大于300px时元素显示，否则不显示

      if (scrollTop >= 900) {
        this.nav_show = true;
      } else {
        this.nav_show = false;
      }
    },
    del() {
      this.seckill_list = null;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll); //监听页面滚动
    this.getbanner();
    this.time = setInterval(() => {
      if (this.remainTime == 0) {
        this.del();
      }
    }, 500);
  },

  created() {
    document.title = `首页-${this.$name.name}官方商城`;
  },

  computed: {},

  watch: {},
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
    clearInterval(this.time);
    this.time = null;
    clearInterval(this.Time);
    this.Time = null;
  },
};
</script>

<style scoped lang="scss">
.classifybottom {
  font-size: 20px;
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.3);
  color: #fff;
  position: absolute;
  bottom: 0;
}

.classifytop {
  font-size: 20px;
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.3);
  color: #fff;
  position: absolute;
  top: 0;
}
.totop {
  width: 60px;

  height: 60px;
  border: 1px solid #a0a0a0;
  border-radius: 50%;
  font-size: 30px;
  display: flex;
  justify-content: center;
  color: #a0a0a0;
  align-items: center;
  position: fixed;
  right: 240px;
  bottom: 340px;
}
.next_anniu {
  width: 200px;
  margin: 0 auto;
  // background: red;
  position: relative;

  top: -30px;

  z-index: 3;
}
.anniu {
  position: absolute;
  width: 30px;
  height: 30px;
  border-radius: 20px;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px soild #fff;
  &_right {
    margin-left: 170px;
    width: 30px;
    height: 30px;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    border: 1px soild #fff;
  }
}
* {
  margin: 0;
  padding: 0;
}

.home_nav_content {
  margin: 0 auto;
  width: 63%;
  height: 100%;
  color: #fff;

  line-height: 40px;
  display: flex;
  justify-content: space-between;

  &_left {
    display: flex;
    align-items: center;
    width: 750px;
  }
}
.nav_rigth {
  width: 300px;
  height: 100%;

  display: flex;
  align-items: center;
}

.Navtext:hover {
  color: red;
}
.item {
  width: 130px;
  height: 50px;
  // background: red;
  display: flex;
  // justify-content: space-around;
  text-align: center;
  margin-left: 20px;
  margin-bottom: 20px;
  line-height: 50px;
  font-size: 12px;
}
.laft_classify {
  width: 15%;
  height: 420px;
  position: relative;
  background: rgba(0, 0, 0, 0.2);
  // background: red;
  position: absolute;
  top: 0px;
  z-index: 99;
}
.classify_content {
  width: 460px;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  text-align: center;
  position: absolute;
  left: 15%;
  top: 0;
  z-index: 99;
}
.classify_content_a {
  width: 300px;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  position: absolute;
  left: 15%;
  top: 0;
  z-index: 99;
}
.classify_content_b {
  width: 145px;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  position: absolute;
  left: 15%;
  top: 0;
  z-index: 99;
}
.text_name {
  width: 70px;
  // background: pink;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.classify_list {
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
}
.list {
  width: 100%;
  height: 50px;
  line-height: 50px;
  cursor: pointer;
  color: #fff;
  display: flex;
  justify-content: space-around;
  &_item {
    width: 100px;
    text-align: center;
  }
  &_img {
    text-align: center;
  }
}
.List {
  width: 100%;
  height: 50px;
  background: rgb(153, 150, 150);
  line-height: 50px;
  cursor: pointer;
  color: #fff;
  display: flex;
  justify-content: space-around;
  &_item {
    width: 100px;
    text-align: center;
  }
  &_img {
    text-align: center;
  }
}

.home_nav {
  width: auto !important;
  height: 96px;
}
// 轮播图
.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}
.red {
  color: red;
  margin: 0 20px;
  cursor: pointer;
}

// ::v-deep .el-carousel__button {
//   border: 1px solid gray;
//   width: 8px;
//   height: 8px;
//   border-radius: 50%;
// }
</style>
