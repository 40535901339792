import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';


import 'element-ui/lib/theme-chalk/index.css';
import name  from "@/api/name"
import VueAwesomeSwiper from 'vue-awesome-swiper'
import '@/components/swiper/swiper.min.css'
Vue.use(VueAwesomeSwiper)

import api from "../src/api/api"
// Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.prototype.$api = api
Vue.prototype.$name = name

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
